import { Controller } from "@hotwired/stimulus"
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  static targets = ["section", "item"]

  connect() {
    useDispatch(this)
    this.sectionsInView = []
  }

  sectionTargetConnected(section) {
    this.#observeSection(section)
  }

  #observeSection(section) {
    const topSentinel = this.#addSentinelToSection("top", section)
    const bottomSentinel = this.#addSentinelToSection("bottom", section)
    this.topsObserver.observe(topSentinel)
    this.bottomsObserver.observe(bottomSentinel)
  }

  #addSentinelToSection(position, section) {
    const sentinel = document.createElement("div")
    sentinel.classList.add("toc-sentinel", `toc-sentinel--${position}`)
    return section.appendChild(sentinel)
  }

  sectionIn(section) {
    this.addIntersectingSection(section)
    this.dispatch("section:in", {
      section: section,
      sectionId: section.id,
      sectionsInView: this.sectionsInView,
    })
    this.sectionInView = section.id
  }

  sectionOut(section) {
    this.removeIntersectingSection(section)
    this.dispatch("section:out", {
      section: section,
      sectionId: section.id,
      sectionsInView: this.sectionsInView,
    })
    if (!this.sectionsInView.length) this.sectionInView = null
  }

  addIntersectingSection(section) {
    const index = this.sectionsInView.indexOf(section)
    if (index < 0) this.sectionsInView.push(section)
  }

  removeIntersectingSection(section) {
    const index = this.sectionsInView.indexOf(section)
    if (index > -1) this.sectionsInView.splice(index, 1)
  }

  topEntry(entry) {
    const { boundingClientRect, rootBounds } = entry
    const section = entry.target.parentElement
    const sectionRect = section.getBoundingClientRect()

    if (!boundingClientRect || !rootBounds || !sectionRect) return

    if (
      boundingClientRect.top <= rootBounds.top &&
      sectionRect.bottom >= rootBounds.top
    ) {
      this.sectionIn(section)
    }

    if (boundingClientRect.top > rootBounds.top) {
      this.sectionOut(section)
    }
  }

  bottomEntry(entry) {
    const { boundingClientRect, rootBounds } = entry
    const section = entry.target.parentElement
    const sectionRect = section.getBoundingClientRect()

    if (!boundingClientRect || !rootBounds || !sectionRect) return

    if (
      boundingClientRect.bottom >= rootBounds.top &&
      sectionRect.top <= rootBounds.top
    ) {
      this.sectionIn(section)
    }

    if (boundingClientRect.bottom < rootBounds.top) {
      this.sectionOut(section)
    }
  }

  set sectionInView(sectionId) {
    if (!this.hasItemTarget) return
    this.data.set("sectionInView", sectionId)
    this.itemTargets.forEach((item) => {
      const isInView = item.hash === `#${sectionId}`
      item.setAttribute("aria-current", isInView.toString())
      if (isInView) this.dispatch("item:current", { item: item })
    })
  }

  get sectionInView() {
    return this.sectionTargets.filter((section) => {
      return section.id === this.data.get("sectionInView")
    })
  }

  get topsObserver() {
    if (!this._topsObserver) {
      this._topsObserver = this.#buildTopsObserver()
    }
    return this._topsObserver
  }

  get bottomsObserver() {
    if (!this._bottomsObserver) {
      this._bottomsObserver = this.#buildBottomsObserver()
    }
    return this._bottomsObserver
  }

  #buildTopsObserver() {
    return new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => this.topEntry(entry))
      },
      { threshold: [0, 0.5, 1] }
    )
  }

  #buildBottomsObserver() {
    return new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => this.bottomEntry(entry))
      },
      { threshold: [0, 1] }
    )
  }
}
