import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["submit", "paymentElement"]
  static values = { darkModeAppearance: Object, lightModeAppearance: Object }

  connect() {
    this.addDarkModeEventListener()
  }

  addDarkModeEventListener() {
    if (!window.matchMedia) return
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        this.updateColorMode()
      })
  }

  submit(event) {
    event.preventDefault()
    this.submitDisabled = true
    this.stripePaymentElementController.confirmPayment()
  }

  updateColorMode() {
    if (this.isDarkMode) {
      this.darkMode()
    } else {
      this.lightMode()
    }
  }

  darkMode() {
    this.stripeElementsAppearance = this.darkModeAppearanceValue
  }

  lightMode() {
    this.stripeElementsAppearance = this.lightModeAppearanceValue
  }

  disableSubmit() {
    this.submitTarget.disabled = true
  }

  enableSubmit() {
    this.submitTarget.disabled = false
  }

  set stripeElementsAppearance(value) {
    this.stripePaymentElementController.updateElements({ appearance: value })
  }

  get isDarkMode() {
    return window.matchMedia("(prefers-color-scheme: dark)").matches
  }

  get stripePaymentElementController() {
    return this.application.getControllerForElementAndIdentifier(
      this.paymentElementTarget,
      "stripe--payment-element"
    )
  }
}
