import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { name: String, props: Object }

  async connect() {
    const { default: component } = await import(
      `~/svelte/${this.nameValue}/index.svelte`
    )
    new component({
      target: this.element.parentElement,
      anchor: this.element,
      props: this._props,
    })
    this.element.remove()
  }

  get _props() {
    let result = {}
    Object.keys(this.propsValue).map((key, _index) => {
      result[key] = this.parseProp(this.propsValue[key])
    })
    return result
  }

  parseProp(prop) {
    try {
      return JSON.parse(prop)
    } catch {
      return prop
    }
  }
}
