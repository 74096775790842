import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect() {
    this.observeMutations()
  }

  observeMutations(
    target = this.element,
    options = {
      attributes: true,
      attributeFilter: ["class", "data"],
      childList: true,
      subtree: true
    }
  ) {
    const observer = new MutationObserver(mutations => {
      observer.disconnect()
      Promise.resolve().then(start)
      this.sortChildren(mutations)
    })
    function start() {
      if (target.isConnected) observer.observe(target, options)
    }
    start()
  }

  sortChildren() {
    const children = this.children
    if (elementsAreSorted(children)) return
    children
      .sort(compareElements)
      .forEach((child, index) => (child.style.order = index))
  }

  append = child => this.element.append(child)

  get children() {
    return Array.from(this.element.children)
  }
}

function elementsAreSorted([left, ...rights]) {
  for (const right of rights) {
    if (compareElements(left, right) > 0) return false
    left = right
  }
  return true
}

function compareElements(left, right) {
  return getSortCode(right) - getSortCode(left)
}

function getSortCode(element) {
  return element.getAttribute("data-sort-code") || 0
}
