import { application } from "./application"
import { registerControllers } from "stimulus-vite-helpers"
import ScrollTo from "stimulus-scroll-to"

const controllers = import.meta.globEager("./**/*_controller.js")
const componentControllers = import.meta.globEager(
  "../../components/**/*_controller.js"
)
registerControllers(application, controllers)
registerControllers(application, componentControllers)
application.register("scroll-to", ScrollTo)
