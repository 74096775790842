import { Turbo } from "@hotwired/turbo-rails"
import morphdom from "morphdom"

Turbo.StreamActions.morph = function () {
  const options = {
    childrenOnly: this.hasAttribute("children-only"),
  }

  this.targetElements.forEach((element) => {
    morphdom(element, this.templateElement.innerHTML, options)
  })
}

import "@asgerb/apparatus/application"
import "@asgerb/apparatus-shop"

import "../controllers"
import "../channels"

import.meta.glob("../images/**/*", { eager: true })
import.meta.glob("../pdfs/**/*", { eager: true })

window.addEventListener("ajax:beforeSend", (event) => {
  const [xhr, _] = event.detail
  if (event.target.dataset.dialog === "true")
    xhr.setRequestHeader("X-Dialog", "true")
})

window.addEventListener("turbo:before-render", ({ detail }) => {
  document.documentElement.dataset.path = detail.newBody.dataset.path
  document.documentElement.dataset.pageType = detail.newBody.dataset.pageType
  document.documentElement.classList = detail.newBody.classList
})
