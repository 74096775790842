import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]
  static values = { url: String }

  connect() {
    this.copy()
    this.element.focus()
  }

  onBlur() {
    setTimeout(() => {
      this.element.remove()
    }, 1000)
  }

  copy() {
    this._copyToClipboard(this.urlValue)
  }

  _copyToClipboard(text) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).catch(() => {
        this._copyToClipboardOld(text)
      })
    } else {
      this._copyToClipboardOld(text)
    }
  }

  _copyToClipboardOld(text) {
    this.inputTarget.type = "text"
    this.inputTarget.value = text
    this.inputTarget.select()
    document.execCommand("copy")
    this.inputTarget.type = "hidden"
  }
}
