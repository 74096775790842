import { Controller } from "@hotwired/stimulus"
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  static values = {
    appearance: Object,
    clientSecret: String,
    elementOptions: Object,
    locale: String,
    publishableKey: String,
    returnUrl: String,
  }
  static targets = ["paymentElement", "errorMessage"]

  errorMessageVisibilityDuration = 5000
  fallbackErrorMessage = "Something went wrong, please try again."

  connect() {
    this.stripe = Stripe(this.publishableKeyValue, {
      locale: this.localeValue,
    })
    useDispatch(this, { eventPrefix: "stripe", debug: true })
    this.setupElements()
  }

  async confirmPayment() {
    this.loading = true
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: this.returnUrlValue,
      },
    })
    this.handleError(error)
    this.loading = false
  }

  updateElements(attributes) {
    this.elements.update(attributes)
  }

  updatePaymentElement(attributes) {
    this.paymentElement.update(attributes)
  }

  handleError(error) {
    switch (error.type) {
      case "card_error":
        this.errorMessage = error.message
        break
      case "validation_error":
        this.errorMessage = error.message
        break
      default:
        this.errorMessage = this.fallbackErrorMessage
    }
  }

  setupElements() {
    this.elements = this.stripe.elements({
      appearance: this.appearanceValue,
      clientSecret: this.clientSecretValue,
    })
    this.paymentElement = this.elements.create(
      "payment",
      this.elementOptionsValue
    )
    this.paymentElement.mount(this.paymentElementTarget)
    this.dispatch("connected")
  }

  set errorMessage(message) {
    this.errorMessageTarget.hidden = false
    this.errorMessageTarget.textContent = message

    setTimeout(() => {
      this.errorMessageTarget.hidden = true
      this.errorMessageTarget.textContent = ""
    }, this.errorMessageVisibilityDuration)
  }

  set loading(isLoading) {
    this.element.classList.toggle(this.loadingClass, isLoading)

    if (isLoading) {
      this.dispatch("loading")
    } else {
      this.dispatch("loaded")
    }
  }
}
