import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static values = {
    discounted: Boolean,
    price: Number,
  }
  static classes = ["selected", "discountedPrice"]

  priceValueChanged() {
    if (!this.discountedValue) return
    this.cleanup = () => {
      this.element.classList.remove(this.discountedPriceClass)
      document.removeEventListener("animationend", this.cleanup)
    }
    document.addEventListener("animationend", this.cleanup)
    this.element.classList.add(this.discountedPriceClass)
  }
}
