import { Controller } from "@hotwired/stimulus"
import cssViewportHeight from "../plugins/css_viewport_height"

export default class extends Controller {
  initialize() {
    this.setVhCssVar()
  }

  handleResize() {
    this.setVhCssVar()
  }

  setVhCssVar() {
    window.requestAnimationFrame(() => {
      document.documentElement.style.setProperty(
        "--vh",
        `${cssViewportHeight()}px`
      )
    })
  }
}
