import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  initialize() {
    this.boundHandleColorModeChange = this.handleColorModeChange.bind(this)
  }

  connect() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", this.boundHandleColorModeChange)
  }

  disconnect() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .removeEventListener("change", this.boundHandleColorModeChange)
  }

  handleColorModeChange(event) {
    if (event.matches) {
      this.dispatch("color-mode:dark")
    } else {
      this.dispatch("color-mode:light")
    }
  }
}
