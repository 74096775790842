import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["family", "selectButton"]
  static classes = ["hasFamily"]

  onShowFamily() {
    this.toggleHasFamilyClass()
  }

  onHideFamily(event) {
    const familyId = event.detail.family.dataset.familyId
    const selectButton = this.getSelectButtonForFamilyId(familyId)
    selectButton.hidden = false
    this.toggleHasFamilyClass()
  }

  toggleHasFamilyClass() {
    this.element.classList.toggle(this.hasFamilyClass, this.hasVisibleFamilies)
  }

  selectFamily(event) {
    const familyId = event.currentTarget.dataset.familyId
    const family = this.getFamilyWithId(familyId)
    const familyController = family.shop__family_component_controller
    familyController.show()
    event.currentTarget.hidden = true
    this.dispatch("select", { family: family })
  }

  getSelectButtonForFamilyId(familyId) {
    return this.selectButtonTargets.filter((element) => {
      return element.dataset.familyId === familyId
    })[0]
  }

  getFamilyWithId(familyId) {
    return this.familyTargets.find(
      (family) => family.dataset.familyId === familyId
    )
  }

  get hasVisibleFamilies() {
    return this.familyTargets.some((family) =>
      family.classList.contains("Shop-Family--visible")
    )
  }
}
