import ApplicationController from "~/controllers/application_controller"
import dialogPolyfill from "dialog-polyfill"

export default class extends ApplicationController {
  initialize() {
    dialogPolyfill.registerDialog(this.element)
  }

  connect() {
    if (this.isOpen) this.open()
  }

  open() {
    this.element.showModal()
  }

  close() {
    this.element.close()
  }

  get isOpen() {
    return JSON.parse(this.data.get("open"))
  }
}
