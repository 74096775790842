import ApplicationController from "~/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["toggle"]
  static classes = ["open", "transitions"]
  static values = { overlay: Boolean }

  toggle() {
    this.element.classList.toggle(this.openClass)
  }

  close(event) {
    if (this.element.contains(event.target)) return
    this.element.classList.remove(this.openClass)
  }
}
