import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  connect() {
    super.connect()
  }

  show() {
    this.element.classList.add(this.data.get("visibleClass"))
    this.element.dataset.sortCode = new Date().getTime()
    this.open = true
    this.dispatch("show", { family: this.element })
  }

  hide() {
    this.element.classList.remove(this.data.get("visibleClass"))
    this.open = false
    this.dispatch("hide", { family: this.element })
  }

  set open(toggled) {
    this.element.open = toggled
  }

  get open() {
    return this.element.open
  }
}
