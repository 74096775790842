import ApplicationController from "../../application_controller"
import { post } from "@rails/request.js"

export default class extends ApplicationController {
  static targets = ["countrySelect", "vats", "form", "total"]
  static values = { vatsUrl: String }

  updateVat() {
    post(this.vatsUrlValue, {
      body: this.formData,
      responseKind: "turbo-stream",
    })
  }

  get formData() {
    return new FormData(this.formTarget)
  }
}
