import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["familySelect"]

  toggleButton(event) {
    event.currentTarget.classList.toggle(this.data.get("buttonActiveClass"))
  }

  toggleFamily(event) {
    const id = event.currentTarget.dataset.id
    this.toggleFamilyOption(id)
  }

  toggleFamilyOption(id) {
    this.getFamilyOptionForId(id).selected = !this.isFamilySelected(id)
  }

  isFamilySelected(id) {
    return this.getFamilyOptionForId(id).selected
  }

  getFamilyOptionForId(id) {
    return this.familySelectTarget.querySelector(`option[value="${id}"]`)
  }
}
