import ApplicationController from "../../application_controller"

export default class extends ApplicationController {
  static targets = ["select", "form"]

  connect() {
    this.updateSelectedDataAttribute()
    super.connect()
  }

  submit() {
    if (!this.hasFormTarget) return
    this.formTarget.requestSubmit()
  }

  toggle() {
    this.element.classList.toggle(this.data.get("openClass"))
    if (!this.isOpen) this.resetSelect()
  }

  updateSelectedDataAttribute() {
    this.selectTarget.dataset.selected = this.selectTarget.value
  }

  resetSelect() {
    this.selectTarget.selectedIndex = 0
    this.submit()
  }

  get isOpen() {
    return this.element.classList.contains(this.data.get("openClass"))
  }
}
