import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["source", "button"]

  connect() {
    if (document.queryCommandSupported("copy"))
      this.element.dataset.clipboardSupported = true
  }

  copy(event) {
    event.preventDefault()
    this.sourceTarget.select()
    document.execCommand("copy")
  }
}
