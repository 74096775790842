import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "input", "label"]
  static classes = ["loading"]

  onSubmitStart() {
    this.loading = true
  }

  onSubmitEnd() {
    this.loading = false
  }

  set loading(value) {
    this.labelTarget.setAttribute("aria-disabled", value)
    this.element.classList.toggle(this.loadingClass, value)
  }
}
