import ApplicationController from "~/controllers/application_controller"

export default class extends ApplicationController {
  connect() {
    setTimeout(() => {
      this.close()
    }, this.readingTime)
  }

  close() {
    this.element.parentNode.removeChild(this.element)
  }

  get text() {
    return this.element.innerText
  }

  get readingTime() {
    const wordsPerMinute = 150
    const wordsPerMillisecond = wordsPerMinute / 60000

    const text = this.text.replace(/<\/?[^>]+(>|$)/g, "") // Remove any HTML-tags
    const totalWords = text.split(/\s+/g).length // Split up in words
    const totalReadingTime = Math.floor(totalWords / wordsPerMillisecond)

    return totalReadingTime
  }
}
